@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500&display=swap");

/* TODO update with proper values */
@layer base {
  a {
    @apply text-teal-600 underline visited:text-teal-600 hover:text-blue-800;
  }
  h1 {
    @apply font-ibm-plex-sans text-4xl font-bold;
  }
  h2 {
    @apply font-ibm-plex-sans text-3xl font-bold;
  }
  h3 {
    @apply font-ibm-plex-sans text-2xl font-bold;
  }
  h4 {
    @apply font-ibm-plex-sans text-xl;
  }
  h5 {
    @apply font-ibm-plex-sans text-lg font-bold;
  }
  h6 {
    @apply font-ibm-plex-sans text-base font-bold;
  }
  p {
    @apply font-ibm-plex-sans text-xs;
  }
  div {
    @apply font-ibm-plex-sans;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
