@tailwind base;
@tailwind components;
@tailwind utilities;

/* These variables contain neutral theme values */
:root {
  /* ======== Top banner =========================================================== */
  --logo-url: url("/public/assets/logos/teambeam.svg");
  --logo-background-color: #ffffff;
  --on-logo-background-color: #1a1a1a;
  --top-banner-wihout-img-bg-color: #ffffff;
  --top-banner-bottom-border-color: #ffffff;
  /* ============================================================================== */

  /* ======== Locale switcher ===================================================== */
  --locale-switcher-font-size: 14px;
  --locale-switcher-border-color: transparent;
  --locale-switcher-hover-border-color: transparent;
  --locale-switcher-text-color: var(--on-logo-background-color);
  --locale-switcher-color: var(--primary-color);
  --locale-switcher-bg-color: var(--primary-light-color);
  --selected-locale-bg-color: var(--primary-light-color);
  /* ============================================================================== */

  /* ======== Options Button ===================================================== */
  --options-button-text-color: #747474;
  --options-button-text-font-size: 14px;
  /* ============================================================================= */

  /* ======== Send Button ======================================================== */
  --send-button-border-radius: 20px;
  --send-button-vertical-padding: 7px;
  --send-button-horizontal-padding: 20px;
  --send-button-hover-bg-color: var(--primary-color);
  /* ============================================================================= */

  /* ======== Text Field ======================================================== */
  --text-field-bg-color: #f7f7f7;
  --text-field-border-radius: 3px;
  /* ============================================================================= */

  --page-bg-color: #ffffff;

  /* ======== Dropzone ============================================================ */
  --dropzone-on-drag-border-color: var(--primary-color);
  --dropzone-error-border-color: var(--warning-color);
  --dropzone-bg-color: #f7f7f7;
  --dropzone-error-bg-color: var(--warning-light-color);
  --dropzone-on-drag-bg-color: var(--primary-light-color);
  --dropzone-file-selector-text-color: var(--primary-color);
  --dropzone-error-file-selector-text-color: var(--warning-color);
  --dropzone-text-color: #747474;
  --dropzone-text-font-weight: 300;
  --dropzone-icon-color: #e6e6e6;
  --dropzone-on-drag-icon-color: var(--primary-color);
  --dropzone-error-icon-color: var(--warning-color);
  /* ============================================================================== */

  /* ======== Footer ============================================================== */
  --footer-bg-color: #f7f7f7;
  --footer-text-color: #797979;
  --footer-link-color: var(--primary-color);
  /* ============================================================================== */

  /* ======== Upload dialog ============================================================== */
  --upload-dialog-linear-gradient: linear-gradient(
    to bottom,
    var(--primary-light-color),
    var(--primary-color)
  );
  --ongoing-upload-circular-progress-bar-color: #dcdcdc;
  --post-succesful-upload-circular-progress-bar-color: var(
    --primary-light-color
  );
  /* ============================================================================== */

  /* ======== Side drawer ============================================================== */
  --acknowlegement-checkbox-color: var(--primary-color);
  --priority-select-button-color: var(--primary-color);
  --slider-linear-gradient: linear-gradient(
    90deg,
    var(--primary-light-color),
    var(--primary-color)
  );
  /* ============================================================================== */

  /* ======== TEXT ================================================================ */

  --font-family: sans-serif;
  --font-style: normal;
  --font-weight: 400;
  /* ============================================================================== */

  --primary-font-color: #1a1a1a;
  --primary-color: #03b4ad;
  --primary-light-color: #c0fcf7;
  --warning-color: #ff0000;
  --warning-light-color: #feebeb;
}
